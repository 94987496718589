<template>
  <div>
    <div class="operate">
      <el-page-header
        :content="$t('admin.addAdmin')"
        @back="goBack"
      />
      <el-button
        style="marginTop:20px"
        type="primary"
        :loading="addLoading"
        @click="confirmAddAdmin"
      >
        {{ $t("common.confirmAdd") }}
      </el-button>
    </div>
    <div class="card">
      <div class="base">
        <el-card>
          <div slot="header">
            {{ $t("addAdmin.adminMsg") }}
          </div>
          <el-form
            ref="adminForm"
            :model="adminForm"
            inline
            :rules="rules"
            label-width="120px"
          >
            <el-form-item
              :label="$t('common.username')"
              prop="LoginName"
            >
              <el-input v-model="adminForm.LoginName" />
            </el-form-item>
            <el-form-item
              :label="$t('common.password')"
              prop="Password"
            >
              <el-input
                v-model="adminForm.Password"
                type="password"
                autocomplete="new-password"
              />
            </el-form-item>
            <el-form-item
              :label="$t('addAdmin.confirmPwd')"
              prop="RePassword"
            >
              <el-input
                v-model="adminForm.RePassword"
                type="password"
              />
            </el-form-item>
            <el-form-item
              :label="$t('addAdmin.role')"
              prop="RoleID"
            >
              <el-select
                v-model="adminForm.RoleID"
                :popper-append-to-body="false"
                :placeholder="$t('common.pleaseChoose')"
              >
                <el-option
                  v-for="item in roleList"
                  :key="item.RoleID"
                  :label="item.RoleName"
                  :value="item.RoleID"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('addAdmin.adminStatus')"
              prop="UserStatus"
            >
              <el-select
                v-model="adminForm.UserStatus"
                :popper-append-to-body="false"
                :placeholder="$t('common.pleaseChoose')"
              >
                <el-option
                  v-for="item in adminStatusList"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('addAdmin.realName')"
              prop="RealName"
            >
              <el-input v-model="adminForm.RealName" />
            </el-form-item>
            <el-form-item
              :label="$t('common.email')"
              prop="Email"
            >
              <el-input v-model="adminForm.Email" />
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { get_all_roles } from '@/api/role.js'
import { add_admin } from '@/api/admin.js'
export default {
  data () {
    // 验证新密码
    const validateNewPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.inputPassword')))
      } else {
        if (this.adminForm.RePassword !== '') {
          this.$refs.adminForm.validateField('RePassword')
        }
        callback()
      }
    }
    // 确认新密码是否一致
    const checkPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('addAdmin.inputPwdAgain')))
      } else if (value !== this.adminForm.Password) {
        callback(new Error(this.$t('addAdmin.pwdNotMatch')))
      } else {
        callback()
      }
    }
    return {
      adminForm: {
        LoginName: '',
        Password: '',
        RePassword: '',
        RoleID: '',
        UserStatus: '',
        Domain: '',
        GroupID: '',
        PowzaUserName: '',
        PowzaPassword: '',
        RealName: '',
        Email: '',
        Type: '1',
        Region: '0',
        Topology: '0',
        ChannelGroup: 'all'
      },
      rules: {
        LoginName: [{
          required: true, message: this.$t('addAdmin.inputUsername'), trigger: 'blur'
        }],
        Password: [{
          required: true, message: this.$t('common.inputPassword'), trigger: 'blur'
        },
        {
          validator: validateNewPwd, trigger: 'blur'
        }],
        RePassword: [{
          required: true, message: this.$t('addAdmin.inputPwdAgain'), trigger: 'blur'
        }, {
          validator: checkPwd, trigger: 'blur'
        }],
        RoleID: [{
          required: true, message: this.$t('addAdmin.chooseRole'), trigger: 'blur'
        }],
        UserStatus: [{
          required: true, message: this.$t('addAdmin.chooseAdminStatus'), trigger: 'blur'
        }]
      },
      roleList: [],
      adminStatusList: [{
        label: this.$t('common.normal'),
        value: 1
      },
      {
        label: this.$t('common.disable'),
        value: 2
      }],
      addLoading: false
    }
  },
  created () {
    this.getAllRoles()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 确认添加管理员
    confirmAddAdmin () {
      this.$refs.adminForm.validate((valid) => {
        if (valid) {
          this.addLoading = true
          add_admin(this.adminForm).then((res) => {
            this.addLoading = false
            if (res.data.code === 0) {
              this.$message.success(this.$t('common.addSuccess'))
              this.$router.back()
            }
          }).catch(() => {
            this.addLoading = false
          })
        }
      })
    },
    // 获取所有角色
    getAllRoles () {
      get_all_roles().then((res) => {
        if (res.data.code === 0) {
          this.roleList = res.data.data
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.operate {
  position: sticky;
  background: #fff;
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 20px;
  border-bottom: 1px solid #cecece;
  top: 0;
  left: 0;
  z-index: 100;
}
.card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 45%;
    margin-right: 20px;
    margin-top: 20px;
  }
  /deep/ .el-card .el-input__inner {
    padding-right: 0;
  }
}

</style>
